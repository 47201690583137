import axios from 'axios'
import { isIE } from 'react-device-detect'
import qs from 'qs'
import useApm from '../hooks/use-apm'

const { PUBLIC_URL } = process.env

const API_URL = {
  auth: process.env.REACT_APP_API_URL,
  catalog: process.env.REACT_APP_CATALOG_API_URL,
}

const API_VERSION = {
  auth: process.env.REACT_APP_API_VERSION,
  catalog: process.env.REACT_APP_CATALOG_API_VERSION,
}

const API_TOKEN = {
  auth: process.env.REACT_APP_API_TOKEN,
}

const APP_TOKEN = {
  catalog: process.env.REACT_APP_CATALOG_API_TOKEN,
}

async function getCSRFToken() {
  if (process.env.NODE_ENV === 'test') return '__TESTING__'
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  if (!token) {
    const urlCsrf = window.location.href.split('/').includes('restore-password')
    const routeCsrf = urlCsrf ? 'csrf-token-no-app' : 'csrf-token'
    const response = await window
      .fetch(`${API_URL.auth}${API_VERSION.auth}/auth/${routeCsrf}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'application-token': API_TOKEN.auth,
        },
        credentials: 'include',
      })
      .catch(error => {
        let tempMessage = error
        if (error && error.message) {
          tempMessage = error.message
        }
        if (typeof tempMessage === 'string') {
          tempMessage = tempMessage.toLowerCase()
        }
        if (
          [
            'networkerror when attempting to fetch resource.', // firefox
            'failed to fetch', // chrome
          ].includes(tempMessage)
        ) {
          throw Error('Servicio temporalmente no disponible')
        }
        throw Error(error)
      })
    const { csrfToken } = await response.json()
    setCSRFToken(csrfToken)
    return csrfToken
  }
  return token
}

function setCSRFToken(token) {
  document
    .querySelector('meta[name="csrf-token"]')
    .setAttribute('content', token)
}

async function client(endpoint, content, api) {
  return isIE
    ? clientAxios(endpoint, content, api)
    : clientFetch(endpoint, content, api)
}

function apmError(apm, config, response, err) {
  apm.setCustomContext({
    fetchConfig: JSON.stringify(config),
    response: JSON.stringify(response),
  })
  apm.captureError(new Error(err))
}

async function clientFetch(
  endpoint,
  {
    body,
    method = 'GET',
    params = null,
    transformResponse = null,
    responseType = 'json',
    ...customConfig
  } = {},
  api
) {
  if (process.env.NODE_ENV === 'test') return '__TESTING__'
  const headers = {
    'content-type': 'application/json',
    'accept-language': localStorage['accept-language'] || 'es-CR',
  }

  if (APP_TOKEN[api]) {
    headers['app-token'] = APP_TOKEN[api]
  }
  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    credentials: 'include',
  }

  let url = `${API_URL[api]}${API_VERSION[api]}${endpoint}`

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (params) {
    const paramsStrings = qs.stringify(params)
    url += `?${paramsStrings}`
  }

  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken()
  }

  return window
    .fetch(url, config)
    .then(async r => {
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        r.status === 401 &&
        !endpoint.includes('login') &&
        !endpoint.includes('me') // HACK: este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
      ) {
        config.headers['X-CSRF-Token'] = await getCSRFToken()
        await window.fetch(`${API_URL.auth}${API_VERSION.auth}/auth/logout`, {
          ...config,
          method: 'POST',
        })
        setCSRFToken('')
        window.location.href = `${PUBLIC_URL}?error=session_expired`
        throw new Error('Token Expired')
      }

      if (r.status !== 200) {
        const response = await r.clone().json()
        const apm = useApm()
        apmError(apm, config, response, response?.message || 'Error')
      }

      return r
    })
    .then(response => {
      if (responseType === 'raw') {
        return response
      }
      if (responseType === 'blob') {
        return response.blob()
      }
      if (responseType === 'text') {
        return response.text()
      }
      if (responseType === 'formData') {
        return response.formData()
      }
      if (responseType === 'arrayBuffer') {
        return response.arrayBuffer()
      }
      return response.json()
    })
    .then(response => {
      if (transformResponse) {
        return transformResponse(response)
      }
      return response
    })
    .catch(error => {
      let tempMessage = error?.message || error
      const apm = useApm()
      apmError(apm, config, error, tempMessage)
      if (typeof tempMessage === 'string') {
        tempMessage = tempMessage.toLowerCase()
      }
      if (
        [
          'networkerror when attempting to fetch resource.', // firefox
          'failed to fetch', // chrome
        ].includes(tempMessage)
      ) {
        throw Error('Servicio temporalmente no disponible')
      }
      throw Error(tempMessage || 'error')
    })
}

async function clientAxios(
  endpoint,
  {
    body,
    method = 'GET',
    params = null,
    transformResponse = null,
    responseType = 'json',
    ...customConfig
  } = {},
  api
) {
  if (process.env.NODE_ENV === 'test') return '__TESTING__'

  const headers = {
    'content-type': 'application/json',
    'accept-language': localStorage['accept-language'] || 'es-CR',
  }

  if (APP_TOKEN[api]) {
    headers['app-token'] = APP_TOKEN[api]
  }

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    credentials: 'include',
  }

  let url = `${API_URL[api]}${API_VERSION[api]}${endpoint}`

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (params) {
    const paramsStrings = qs.stringify(params)
    url += `?${paramsStrings}`
  }

  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken()
  }

  return axios({
    method,
    url,
    timeout: 120000,
    data: JSON.stringify(body),
    headers: config.headers,
    withCredentials: true,
  })
    .then(response => {
      // se hace este fix, ya que el codigo 202 el cliente no lo toma como un error
      // el 202 solo se envia al hacer SEND del tramite en horario fuera de atencion, por lo que no afectaria el resto de requests del cliente
      if (response?.data?.statusCode === 202) {
        return Promise.reject(response.data)
      }
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        [401, 512].includes(response.status) &&
        !endpoint.includes('login') &&
        !endpoint.includes('logout') &&
        !endpoint.includes('me') // este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
      ) {
        throw new Error(
          response.status === 512
            ? { statusCode: 512, message: response.message }
            : 'Token Expired'
        )
      }

      const contentType = response.headers['content-type']
      if (
        contentType &&
        (contentType.indexOf('application/xml') !== -1 ||
          contentType.indexOf('application/pdf') !== -1 ||
          contentType.indexOf('text/csv') !== -1 ||
          contentType.indexOf('application/vnd.ms-excel') !== -1)
      ) {
        return response.data.blob()
      }

      if (responseType === 'raw') {
        return response.data
      }
      if (responseType === 'blob') {
        return response.data.blob()
      }
      if (responseType === 'text') {
        return response.data.text()
      }
      if (responseType === 'formData') {
        return response.data.formData()
      }
      if (responseType === 'arrayBuffer') {
        return response.data.arrayBuffer()
      }

      return response.data
    })
    .then(response => {
      if (transformResponse) {
        return transformResponse(response)
      }
      return response
    })
    .catch(err => {
      // error que indica que se debe hacer reload
      let error = {
        message:
          err?.response?.data?.message ||
          err?.message ||
          'Ha ocurrido un problema vuelva a intentarlo',
        statusCode: err?.response?.data?.statusCode || err?.statusCode || 500,
      }

      if (/^failed/gi.test(err) || /^failed/gi.test(err?.message)) {
        error = {
          ...error,
          message: 'Servicios no disponibles temporalmente.',
        }
      }
      if (
        /^NetworkError when attempting/gi.test(err) ||
        /^Network Error/gi.test(err) ||
        /^Network Error/gi.test(err?.message) ||
        /^NetworkError when attempting/gi.test(err?.message)
      ) {
        error = {
          ...error,
          message: 'No tiene conexión a internet.',
        }
      }
      return Promise.reject(error)
    })
}

export { client, clientFetch, clientAxios, getCSRFToken, setCSRFToken }
