const account = require('./account.json')
const application = require('./application.json')
const applicationType = require('./applicationType.json')
const authentication = require('./authentication.json')
const authenticationMethod = require('./authenticationMethod.json')
const blockReason = require('./blockReason.json')
const common = require('./common.json')
const tenantConfig = require('./tenantConfig.json')
const connection = require('./connection.json')
const endpoints = require('./endpoints.json')
const errors = require('./errors.json')
const identificationType = require('./identificationType.json')
const ldap = require('./ldap.json')
const pendingUser = require('./pendingUser.json')
const permission = require('./permission.json')
const permissionActions = require('./permissionActions.json')
const permissionEntities = require('./permissionEntities.json')
const policies = require('./policies.json')
const restorePassword = require('./restorePassword.json')
const role = require('./role.json')
const tenant = require('./tenant.json')
const tenantType = require('./tenantType.json')
const traceabilityLogin = require('./traceabilityLogin.json')
const traceabilityLoginType = require('./traceabilityLoginType.json')
const user = require('./user.json')
const userRole = require('./userRole.json')
const emailTemplate = require('./emailTemplate.json')
const branding = require('./branding.json')
const toggle = require('./toggle.json')
const traceabilityUserLock = require('./traceabilityUserLock.json')
const applicationToggle = require('./applicationToggle.json')
const applicationConfiguration = require('./applicationConfiguration.json')
const applicationAuthenticationMethod = require('./applicationAuthenticationMethod.json')
const tenantAuthenticationMethod = require('./tenantAuthenticationMethod.json')
const roleClassification = require('./roleClassification.json')
const roleType = require('./roleTypes.json')
const paramValue = require('./paramValue.json')
const applicationParam = require('./applicationParam.json')
const userIdentification = require('./userIdentification.json')
const permissions = require('./permissions.json')
const locations = require('./location.json')
const userLocations = require('./userLocation.json')
const origin = require('./origin.json')
const locationRoles = require('./locationRole.json')
const roleOriginApplication = require('./roleOriginApplication.json')
const tenantOrigin = require('./tenantOrigin.json')
const entityAssociation = require('./entityAssociation.json')
const userApplication = require('./userApplication.json')
const syncTramiteYa = require('./syncTramiteYa.json')

module.exports = {
  tenantOrigin,
  roleOriginApplication,
  locationRoles,
  userLocations,
  origin,
  locations,
  permissions,
  roleClassification,
  roleType,
  applicationParam,
  paramValue,
  account,
  application,
  applicationType,
  authentication,
  authenticationMethod,
  blockReason,
  common,
  tenantConfig,
  connection,
  endpoints,
  errors,
  identificationType,
  ldap,
  pendingUser,
  permission,
  permissionActions,
  permissionEntities,
  policies,
  restorePassword,
  role,
  tenant,
  tenantType,
  traceabilityLogin,
  traceabilityLoginType,
  user,
  userRole,
  emailTemplate,
  branding,
  toggle,
  traceabilityUserLock,
  applicationToggle,
  applicationConfiguration,
  applicationAuthenticationMethod,
  tenantAuthenticationMethod,
  userIdentification,
  entityAssociation,
  userApplication,
  syncTramiteYa,
}
