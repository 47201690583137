/** @jsxImportSource @emotion/react */
import React from 'react'

export const SvgIcon = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <svg
      css={{
        color: 'black',
        overflow: 'visible',
      }}
      fill="none"
      {...props}
      ref={ref}
    >
      {children}
    </svg>
  )
})
SvgIcon.displayName = 'SvgIcon'

export default function svgToIcon({ paths, name, ...rest }) {
  const Icon = (props, ref) => (
    <SvgIcon ref={ref} {...rest} {...props}>
      {paths}
    </SvgIcon>
  )
  Icon.displayName = name

  return React.memo(React.forwardRef(Icon))
}
