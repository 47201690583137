/**
 * List of colors to customize the app
 */
const common = {
  cerulean: '#046FA9',
  hawkesBlue: '#BFD7FF',
  lightSkyBlue: '#94BAF9',
  prussianBlue: '#004266',
  sanMarino: '#506D9C',
  solitude: '#E8F0FF',
  white: '#FFFFFF',
}

const redimed = {
  ...common,
  amaranthPurple: '#a63446',
  black: '#000000',
  charlestonGreen: '#272932',
  lightCarminePink: '#EE6379',
}

const biondemand = {
  ...common,
  columbiaBlue: '#c9dfe7',
  darkSkyBlue: '#93bfce',
  deepSpaceSparkle: '#4a6067',
  mintCream: '#f4f9fa',
  orangeWeb: '#FFA500',
  outerSpaceCrayola: '#2f343f',
  policeBlue: '#3d5065',
  shadowBlue: '#7195A1',
  slateGray: '#707b89',
}

const tramiteya = {
  ...common,
  azureishWhite: '#d4e4ed',
  cadetBlueCrayola: '#A0B9C8',
  columbiaBlue: '#c9dfe7',
  darkJungleGreen: '#0d1f2d',
  darkSlateGray: '#214A61',
  honoluluBlue: '#0672ab',
  midnightGreen: '#08415c',
  redSalsa: '#F93545',
  richElectricBlue: '#1896d8',
}

const registrelo = {
  ...common,
  azureishWhite: '#d4e4ed',
  cadetBlueCrayola: '#A0B9C8',
  columbiaBlue: '#c9dfe7',
  darkJungleGreen: '#0d1f2d',
  darkSlateGray: '#214A61',
  honoluluBlue: '#0672ab',
  midnightGreen: '#08415c',
  redSalsa: '#F93545',
  richElectricBlue: '#1896d8',
}

const tdcar = {
  ...common,
  azureishWhite: '#d4e4ed',
  cadetBlueCrayola: '#A0B9C8',
  columbiaBlue: '#c9dfe7',
  darkJungleGreen: '#0d1f2d',
  darkSlateGray: '#214A61',
  honoluluBlue: '#0672ab',
  midnightGreen: '#08415c',
  redSalsa: '#F93545',
  richElectricBlue: '#1896d8',
}

export { redimed, biondemand, tramiteya, registrelo, tdcar }
