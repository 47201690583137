import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <path
      d="M16 0C7.168 0 0 7.16 0 16C0 24.84 7.168 32 16 32C24.832 32 32 24.84 32 16C32 7.16 24.832 0 16 0ZM17.6 24H14.4V20.8H17.6V24ZM17.6 17.6H14.4V8H17.6V17.6Z"
      fill="white"
    />
  ),
  name: 'ExclamationCircle',
})
