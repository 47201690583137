import { getUser } from './auth-client'

async function bootstrapData({ login }) {
  try {
    const userData = await getUser()

    if (!userData || !userData?.data) {
      return { user: null }
    }

    return { user: userData.data }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return {
      user: null,
      ...(login && error.statusCode === 403 && { error }),
    }
  }
}

export default bootstrapData
