import React from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './auth-context'
import { UserProvider } from './user-context'
import { ReferenceProvider } from './reference-context'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
})

function AppProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ReferenceProvider>
          <UserProvider>{children}</UserProvider>
        </ReferenceProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default AppProvider
