import React from 'react'

const ReferenceContext = React.createContext()

function ReferenceProvider(props) {
  const [reference, setReference] = React.useState(null)
  const [appCode, setAppCode] = React.useState(null)

  function addReference(url) {
    setReference(url)
  }

  function addApp(code) {
    setAppCode(code)
  }

  return (
    <ReferenceContext.Provider
      {...props}
      value={{ reference, appCode, addReference, addApp }}
    />
  )
}

function useReference() {
  const context = React.useContext(ReferenceContext)

  if (context === undefined) {
    throw new Error(
      'useRoute debe ser usado dentro del provider ReferenceContext'
    )
  }

  return context
}

export { ReferenceProvider, useReference }
