import React from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'

import bootstrapData from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import LoadingFallback from '../components/LoadingFallback'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const { t } = useTranslation()
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
  const [fetchLogin, setFetchLogin] = React.useState(false)
  const [origin, setOrigin] = React.useState()
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
    setData,
  } = useAsync({ promiseFn: bootstrapData, login: fetchLogin })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
  }, [isSettled])

  const logout = React.useCallback(async () => {
    await authClient.logout()
    window.history.pushState(null, null, process.env.REACT_APP_HOME_PATH)
    setData({ user: null })
  }, [setData])

  const login = React.useCallback(
    loginData =>
      authClient
        .login(loginData)
        .then(res => {
          setFetchLogin(res === 'OK')
          return res
        })
        .then(res => {
          if (origin !== 'MISE') {
            reload()
          }
          return res
        })
        .finally(() => setFetchLogin(false)),
    [reload, origin]
  )

  const loginBySignature = token =>
    authClient.loginBySignature(token).then(reload)

  const recoverPasswordByEmail = React.useCallback(
    emailData => authClient.recoverPasswordByEmail(emailData),
    [reload]
  )

  const resetPassword = React.useCallback(
    (passwordData, token) =>
      authClient.resetPassword({ password: passwordData }, token),
    [reload]
  )

  const findOrigin = React.useCallback(
    async username => {
      const response = await authClient.findOrigin(username)
      if (response) {
        const {
          Origin: { code },
        } = response
        setOrigin(code)
      }
      return response
    },
    [reload]
  )

  const changeRoles = React.useCallback(
    (userId, roleId) => authClient.changeRoles(userId, roleId).then(reload),
    [reload]
  )

  const values = React.useMemo(
    () => ({
      data,
      logout,
      login,
      loginBySignature,
      recoverPasswordByEmail,
      resetPassword,
      findOrigin,
      changeRoles,
    }),
    [
      data,
      logout,
      login,
      loginBySignature,
      recoverPasswordByEmail,
      resetPassword,
      findOrigin,
      changeRoles,
    ]
  )

  if (!firstAttemptFinished) {
    if (isPending) {
      return <LoadingFallback />
    }

    if (isRejected) {
      return (
        <div style={{ color: 'red' }}>
          <p>{`${t(
            'Uh oh... Ha ocurrido un problema. Trate de refrescar el App'
          )}.`}</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  return <AuthContext.Provider value={values} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth debe ser utilizado dentro de AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
