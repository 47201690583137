import { client, setCSRFToken } from './api-client'

async function logout() {
  await client('/auth/logout', { method: 'POST' }, 'auth').catch(() => {
    return Promise.resolve()
  })
  setCSRFToken('')

  return Promise.resolve()
}

async function getUser() {
  try {
    const response = await client('/auth/me', {}, 'auth')

    if (response.statusCode === 403) {
      return Promise.reject(response)
    }

    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

async function login({ body }) {
  const response = await client(
    '/auth/login',
    { body, method: 'POST' },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })

  if (checkPasswordExpirationOnMise(response)) {
    const { externalLogin } = response
    const miseError = {
      ...response,
      externalLogin,
    }
    return Promise.reject(miseError)
  }

  if (response.statusCode !== 200) {
    return Promise.reject(response)
  }

  return 'OK'
}

function checkPasswordExpirationOnMise(response) {
  const { externalLogin } = response
  return (
    externalLogin?.codigo === 618 && externalLogin?.indicadorCambioClave === 1
  )
}

async function sendVerificationEmail({ body }) {
  const response = await client(
    `/auth/sendVerificationEmail`,
    {
      body,
      method: 'POST',
    },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })
  if (response.statusCode !== 200) {
    return Promise.reject(response.message)
  }

  return 'OK'
}

async function recoverPasswordByEmail({ body }) {
  const response = await client(
    `/auth/password/recoveryByMail`,
    {
      body,
      method: 'POST',
    },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })
  if (response.statusCode !== 200) {
    return Promise.reject(response.message)
  }

  return response
}

async function resetPassword(body, token) {
  const response = await client(
    `/auth/password/restore/${token}`,
    {
      body,
      method: 'POST',
    },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })
  if (response.statusCode !== 200) {
    return Promise.reject(response.message)
  }

  return response
}

async function changeRoles(userId, roleId) {
  const response = await client(
    `/users/${userId}/roles/changes/${roleId}`,
    { method: 'PUT' },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })

  return response?.data
}

async function findOrigin(username) {
  try {
    const response = await client(
      `/users/${username}/origin`,
      {
        method: 'GET',
      },
      'auth'
    )

    if (response.statusCode !== 200) {
      throw response
    }

    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function loginBySignature(token) {
  const response = await client(
    '/auth/loginByToken',
    {
      body: { token },
      method: 'POST',
    },
    'auth'
  ).catch(error => {
    return Promise.reject(error)
  })
  if (response.statusCode !== 200) {
    return Promise.reject(response)
  }

  return 'OK'
}

async function setApplicationIdInCookie() {
  try {
    const response = await client(
      `/setApplicationIdInCookie`,
      { method: 'GET' },
      'auth'
    )

    if (response.statusCode !== 200) {
      throw response
    }

    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}
export {
  changeRoles,
  findOrigin,
  login,
  logout,
  getUser,
  sendVerificationEmail,
  recoverPasswordByEmail,
  resetPassword,
  loginBySignature,
  setApplicationIdInCookie,
}
