/**
 * Esta función puede ser utilizado como reducer de los hooks
 * useReducer genéricos
 * @param {*} currentState
 * @param {*} newState
 * @return Object reduced
 */
function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

/**
 * Esta función recibe un parametro y pregunta si el valor que
 * recibe es primitivo (string, boolean, numbers) o no
 * @param {*} val
 * @return Boolean
 */
function isPrimitive(val) {
  return val === null || /^[sbn]/.test(typeof val)
}

const breakpoints = [576, 768, 992, 1200]
const mediaQuery = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

export { reducer, isPrimitive, mediaQuery }
