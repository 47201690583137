const api = require('./api')
const translationWebCommon = require('../common/en/ui/translations-web.json')

exports.api = {
  ...api,
}

exports.web = {
  'translations-web': translationWebCommon,
}
