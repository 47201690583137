import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { ToastProvider } from 'react-toast-notifications'
import './index.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import App from './App'
import CustomToast from './components/CustomToast'
import AppProvider from './context/app-context'
import './utils/i18n'

ReactDOM.render(
  <ToastProvider autoDismissTimeout={10000} components={{ Toast: CustomToast }}>
    <AppProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </AppProvider>
  </ToastProvider>,
  document.getElementById('root')
)
