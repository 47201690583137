import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: '24',
  height: '16',
  viewBox: '0 -1 24 16',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <path
      d="M12 0C6.54545 0 1.88727 3.31733 0 8C1.88727 12.6827 6.54545 16 12 16C17.4545 16 22.1127 12.6827 24 8C22.1127 3.31733 17.4545 0 12 0ZM12 13.3333C8.98909 13.3333 6.54545 10.944 6.54545 8C6.54545 5.056 8.98909 2.66667 12 2.66667C15.0109 2.66667 17.4545 5.056 17.4545 8C17.4545 10.944 15.0109 13.3333 12 13.3333ZM12 4.8C10.1891 4.8 8.72727 6.22933 8.72727 8C8.72727 9.77067 10.1891 11.2 12 11.2C13.8109 11.2 15.2727 9.77067 15.2727 8C15.2727 6.22933 13.8109 4.8 12 4.8Z"
      fill="#A63446"
    />
  ),
  name: 'Eye',
})
