import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.393 10.4301C22.393 10.4301 15.335 22.73 15.213 22.84C14.81 23.22 14.177 23.2 13.8 22.8L9.228 18.58C8.85 18.18 8.871 17.54 9.274 17.17C9.677 16.79 10.31 16.81 10.688 17.21L14.266 20.51L20.661 9.43005C20.937 8.96005 21.548 8.78995 22.027 9.06995C22.505 9.33995 22.669 9.96005 22.393 10.4301ZM16 0C7.164 0 0 7.16 0 16C0 24.84 7.164 32 16 32C24.836 32 32 24.84 32 16C32 7.16 24.836 0 16 0Z"
      fill="white"
    />
  ),
  name: 'CheckCircle',
})
