import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: 'null',
  height: 'null',
  viewBox: '0 0 31 31',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 0.916504C7.43541 0.916504 0.916656 7.43525 0.916656 15.4998C0.916656 23.5644 7.43541 30.0832 15.5 30.0832C23.5646 30.0832 30.0833 23.5644 30.0833 15.4998C30.0833 7.43525 23.5646 0.916504 15.5 0.916504ZM21.7708 21.7707C21.6359 21.9059 21.4757 22.0131 21.2992 22.0863C21.1228 22.1595 20.9337 22.1972 20.7427 22.1972C20.5517 22.1972 20.3626 22.1595 20.1862 22.0863C20.0097 22.0131 19.8495 21.9059 19.7146 21.7707L15.5 17.5561L11.2854 21.7707C11.0127 22.0433 10.6429 22.1965 10.2573 22.1965C9.87166 22.1965 9.50183 22.0433 9.22916 21.7707C8.95648 21.498 8.80329 21.1282 8.80329 20.7425C8.80329 20.5516 8.8409 20.3625 8.91397 20.1861C8.98704 20.0097 9.09414 19.8494 9.22916 19.7144L13.4437 15.4998L9.22916 11.2853C8.95648 11.0126 8.80329 10.6428 8.80329 10.2571C8.80329 9.87151 8.95648 9.50168 9.22916 9.229C9.50183 8.95633 9.87166 8.80314 10.2573 8.80314C10.6429 8.80314 11.0127 8.95633 11.2854 9.229L15.5 13.4436L19.7146 9.229C19.8496 9.09399 20.0099 8.98689 20.1863 8.91382C20.3627 8.84075 20.5518 8.80314 20.7427 8.80314C20.9336 8.80314 21.1227 8.84075 21.2991 8.91382C21.4755 8.98689 21.6358 9.09399 21.7708 9.229C21.9058 9.36402 22.0129 9.52431 22.086 9.70071C22.1591 9.87712 22.1967 10.0662 22.1967 10.2571C22.1967 10.4481 22.1591 10.6371 22.086 10.8135C22.0129 10.99 21.9058 11.1502 21.7708 11.2853L17.5562 15.4998L21.7708 19.7144C22.325 20.2686 22.325 21.2019 21.7708 21.7707Z"
          fill="white"
        />
      </g>
    </>
  ),
  name: 'TimesCircle',
})
