const { api: esApi, web: esWeb } = require('./es')
const { api: esCRApi, web: esCRWeb } = require('./es-CR')
const { api: enApi, web: enWeb } = require('./en')
const { api: enUSApi, web: enUSWeb } = require('./en-US')

exports.api = {
  es: esApi,
  'es-CR': esCRApi,
  en: enApi,
  'en-US': enUSApi,
}

exports.web = {
  es: esWeb,
  'es-CR': esCRWeb,
  en: enWeb,
  'en-US': enUSWeb,
}
