/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'

import { mediaQuery } from '../utils'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  TimesCircleIcon,
} from './icons'

const { REACT_APP_CURRENT_APP } = process.env
const icon = {
  redimed: {
    successBackgroundColor: '#94BAF9',
    successTextColor: '#272932',
    successIconColor: '#046FA9',
    successIconBackgroundColor: '#E8F0FF',
    errorBackgroundColor: '#A63446',
    errorTextColor: '#FFFFFF',
    errorIconColor: '#A63446',
    errorIconBackgroundColor: '#E8F0FF',
    successTimesIconColor: '#272932',
    errorTimesIconColor: '#FFFFFF',
  },
  biondemand: {
    successBackgroundColor: '#F4F5F8',
    successTextColor: '#2F343F',
    successIconColor: '#7195A1',
    successIconBackgroundColor: '#C9DFE7',
    errorBackgroundColor: '#FAE9E8',
    errorTextColor: '#2F343F',
    errorIconColor: '#DB3A34',
    errorIconBackgroundColor: '#F1B0AE',
    successTimesIconColor: '#7195A1',
    errorTimesIconColor: '#DB3A34',
  },
  tramiteya: {
    successBackgroundColor: '#0F7CB5',
    successTextColor: '#FFFFFF',
    successIconColor: '#0F7CB5',
    successIconBackgroundColor: '#EBF1F4',
    errorBackgroundColor: '#CE1625',
    errorTextColor: '#FFFFFF',
    errorIconColor: '#CE1625',
    errorIconBackgroundColor: '#EBF1F4',
    successTimesIconColor: '#FFFFFF',
    errorTimesIconColor: '#FFFFFF',
  },
  registrelo: {
    successBackgroundColor: '#0F7CB5',
    successTextColor: '#FFFFFF',
    successIconColor: '#0F7CB5',
    successIconBackgroundColor: '#EBF1F4',
    errorBackgroundColor: '#CE1625',
    errorTextColor: '#FFFFFF',
    errorIconColor: '#CE1625',
    errorIconBackgroundColor: '#EBF1F4',
    successTimesIconColor: '#FFFFFF',
    errorTimesIconColor: '#FFFFFF',
  },

  tdcar: {
    successBackgroundColor: '#0F7CB5',
    successTextColor: '#FFFFFF',
    successIconColor: '#0F7CB5',
    successIconBackgroundColor: '#EBF1F4',
    errorBackgroundColor: '#CE1625',
    errorTextColor: '#FFFFFF',
    errorIconColor: '#CE1625',
    errorIconBackgroundColor: '#EBF1F4',
    successTimesIconColor: '#FFFFFF',
    errorTimesIconColor: '#FFFFFF',
  },
}

/**
 * This component allows you to display a custom toast.
 * @param {string} title - Toast title.
 * @param {string} appearance - Toast type.
 * @param {Object} children - Children component to display into the component.
 * @param {function} onDismiss - Callback to handle dismiss event.
 */
export default function CustomToast({
  title,
  children,
  appearance,
  onDismiss,
}) {
  let toastBackground = ''
  let textColor = ''
  let IconImage = null
  let iconBackgroundColor = ''
  let iconColor = ''
  let timesIconColor = ''

  switch (appearance) {
    case 'error':
      toastBackground = icon[REACT_APP_CURRENT_APP].errorBackgroundColor
      textColor = icon[REACT_APP_CURRENT_APP].errorTextColor
      IconImage = ExclamationCircleIcon
      iconBackgroundColor = icon[REACT_APP_CURRENT_APP].errorIconBackgroundColor
      iconColor = icon[REACT_APP_CURRENT_APP].errorIconColor
      timesIconColor = icon[REACT_APP_CURRENT_APP].errorTimesIconColor
      break
    default:
      toastBackground = icon[REACT_APP_CURRENT_APP].successBackgroundColor
      textColor = icon[REACT_APP_CURRENT_APP].successTextColor
      IconImage = CheckCircleIcon
      iconBackgroundColor =
        icon[REACT_APP_CURRENT_APP].successIconBackgroundColor
      iconColor = icon[REACT_APP_CURRENT_APP].successIconColor
      timesIconColor = icon[REACT_APP_CURRENT_APP].successTimesIconColor
      break
  }

  return (
    <div
      css={styles({
        toastBackground,
        textColor,
        iconBackgroundColor,
        iconColor,
      })}
    >
      <div className="toast">
        <div className="toast-img-container">
          <IconImage
            css={css`
              width: 7.4rem;
              height: 3.3rem;
              color: ${iconColor};
            `}
          />
        </div>
        <div
          className={
            appearance === 'error' ? 'error-toast-detail' : 'toast-detail'
          }
        >
          <h1 aria-label="Title">{title}</h1>
          <p>{children}</p>
        </div>
        <TimesCircleIcon
          onClick={onDismiss}
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            width: 2rem;
            height: 2rem;
            color: ${timesIconColor};

            &:hover {
              cursor: pointer;
            }
          `}
        />
      </div>
    </div>
  )
}
const styles = ({ toastBackground, textColor, iconBackgroundColor }) => css`
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  display: -ms-flexbox;
  width: 45.1rem;
  height: 11.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  background-color: ${toastBackground};
  border-radius: 6px;
  margin-top: 1rem;
  margin-right: 1rem;

  ${mediaQuery[0]} {
    width: 40rem;
    margin-top: 1rem;
  }
  -webkit-animation: slidein 1s ease-in; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slidein 1s ease-in; /* Firefox < 16 */
  -ms-animation: slidein 1s ease-in; /* Internet Explorer */
  -o-animation: slidein 1s ease-in; /* Opera < 12.1 */
  animation: slidein 1s ease-in;

  @keyframes slidein {
    from {
      transform: translateX(20rem);
    }
    to {
      transform: translateX(0);
    }
  }

  h1 {
    font-family: 'Grotesque';
    font-style: normal;
    font-size: 2rem;
    line-height: 2.7rem;
    letter-spacing: 0.01em;
    color: ${textColor};
    margin-bottom: 3px;
  }
  p {
    font-family: 'IBMPlexSans';
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.01em;
    color: ${textColor};
    ${mediaQuery[0]} {
      font-size: 1.3rem;
    }
  }
  .toast-img-container {
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    background-color: ${iconBackgroundColor};
    width: 8.4rem;
    height: 5.3rem;
    border-radius: 1.9rem;
    @media (max-width: 1600px) {
      height: 5.5rem;
    }
    ${mediaQuery[3]} {
      width: 10rem;
      height: 6rem;
    }
    ${mediaQuery[1]} {
      width: 11rem;
      height: 7rem;
    }
    ${mediaQuery[0]} {
      width: 9.8rem;
      height: 5.8rem;
    }
  }
  .toast {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex: 1;
    justify-content: center;
    flex-basis: 70%;
    color: #ffffff;
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
    align-items: center;
    justify-content: flex-start;
  }
  .toast-detail {
    margin-left: 1.4rem;
  }
  .error-toast-detail {
    margin-left: 1.4rem;
  }
`
