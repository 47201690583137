import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <path
      d="M11.8145 8.63158L15.2727 12.1242C15.2727 12.0689 15.2727 12.0026 15.2727 11.9474C15.2727 11.068 14.9279 10.2246 14.3142 9.60275C13.7004 8.98092 12.868 8.63158 12 8.63158C11.9345 8.63158 11.88 8.63158 11.8145 8.63158ZM7.12364 9.51579L8.81455 11.2289C8.76 11.4611 8.72727 11.6932 8.72727 11.9474C8.72727 12.8268 9.07208 13.6702 9.68583 14.292C10.2996 14.9138 11.132 15.2632 12 15.2632C12.24 15.2632 12.48 15.23 12.7091 15.1747L14.4 16.8879C13.6691 17.2526 12.8618 17.4737 12 17.4737C10.5534 17.4737 9.16598 16.8914 8.14305 15.8551C7.12013 14.8187 6.54545 13.413 6.54545 11.9474C6.54545 11.0742 6.76364 10.2563 7.12364 9.51579ZM1.09091 3.40368L3.57818 5.92368L4.06909 6.42105C2.26909 7.85789 0.850909 9.73684 0 11.9474C1.88727 16.7995 6.54545 20.2368 12 20.2368C13.6909 20.2368 15.3055 19.9053 16.7782 19.3084L17.2473 19.7726L20.4327 23L21.8182 21.5963L2.47636 2L1.09091 3.40368ZM12 6.42105C13.4466 6.42105 14.834 7.00329 15.8569 8.03967C16.8799 9.07606 17.4545 10.4817 17.4545 11.9474C17.4545 12.6547 17.3127 13.34 17.0618 13.9589L20.2582 17.1974C21.8945 15.8158 23.2036 14.0032 24 11.9474C22.1127 7.09526 17.4545 3.65789 12 3.65789C10.4727 3.65789 9.01091 3.93421 7.63636 4.43158L10.0036 6.80789C10.6255 6.56474 11.2909 6.42105 12 6.42105Z"
      fill="#A63446"
    />
  ),
  name: 'EyeOff',
})
