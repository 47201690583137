import React, { useEffect } from 'react'

import { useUser } from './context/user-context'
import LoadingFallback from './components/LoadingFallback'

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnAuthenticatedApp = React.lazy(() => import('./UnAuthenticatedApp'))

const createHost = require('cross-domain-storage/host')

const storageHost = createHost([
  {
    origin: process.env.REACT_APP_CROSS_DOMAIN_STORAGE,
    allowedMethods: ['get', 'remove'],
  },
])

export default function App() {
  const { user } = useUser()

  useEffect(() => {
    return () => {
      storageHost.close()
    }
  }, [])

  return (
    <React.Suspense fallback={<LoadingFallback />}>
      {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  )
}
