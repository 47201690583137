import React from 'react'
import { css } from '@emotion/react'

import svgToIcon from '../../utils/svgToIcon'

export default svgToIcon({
  width: '163',
  height: '25',
  viewBox: '0 0 163 25',
  css: css({ path: { fill: 'currentcolor' } }),
  paths: (
    <>
      <g clipPath="url(#clip0)">
        <path
          d="M0.312012 18V6.832H5.33601C5.84801 6.832 6.30668 6.91734 6.71201 7.088C7.12801 7.248 7.48001 7.48267 7.76801 7.792C8.05601 8.09067 8.27468 8.45334 8.42401 8.88C8.57335 9.30667 8.64801 9.776 8.64801 10.288C8.64801 10.8107 8.57335 11.2853 8.42401 11.712C8.27468 12.128 8.05601 12.4907 7.76801 12.8C7.48001 13.0987 7.12801 13.3333 6.71201 13.504C6.30668 13.664 5.84801 13.744 5.33601 13.744H2.42401V18H0.312012ZM2.42401 11.92H5.14401C5.54935 11.92 5.86935 11.8133 6.10401 11.6C6.33868 11.376 6.45601 11.0613 6.45601 10.656V9.92C6.45601 9.51467 6.33868 9.20534 6.10401 8.992C5.86935 8.77867 5.54935 8.672 5.14401 8.672H2.42401V11.92Z"
          fill="#FFA500"
        />
        <path
          d="M13.746 18.192C13.1487 18.192 12.61 18.0907 12.13 17.888C11.6607 17.6853 11.2553 17.392 10.914 17.008C10.5833 16.624 10.3273 16.1653 10.146 15.632C9.96468 15.088 9.87401 14.48 9.87401 13.808C9.87401 13.136 9.96468 12.5333 10.146 12C10.3273 11.4667 10.5833 11.0133 10.914 10.64C11.2553 10.256 11.6607 9.96267 12.13 9.76C12.61 9.55734 13.1487 9.456 13.746 9.456C14.3433 9.456 14.882 9.55734 15.362 9.76C15.842 9.96267 16.2473 10.256 16.578 10.64C16.9193 11.0133 17.1807 11.4667 17.362 12C17.5433 12.5333 17.634 13.136 17.634 13.808C17.634 14.48 17.5433 15.088 17.362 15.632C17.1807 16.1653 16.9193 16.624 16.578 17.008C16.2473 17.392 15.842 17.6853 15.362 17.888C14.882 18.0907 14.3433 18.192 13.746 18.192ZM13.746 16.544C14.29 16.544 14.7167 16.3787 15.026 16.048C15.3353 15.7173 15.49 15.232 15.49 14.592V13.04C15.49 12.4107 15.3353 11.9307 15.026 11.6C14.7167 11.2693 14.29 11.104 13.746 11.104C13.2127 11.104 12.7913 11.2693 12.482 11.6C12.1727 11.9307 12.018 12.4107 12.018 13.04V14.592C12.018 15.232 12.1727 15.7173 12.482 16.048C12.7913 16.3787 13.2127 16.544 13.746 16.544Z"
          fill="#FFA500"
        />
        <path
          d="M18.493 9.648H20.461L21.309 13.088L21.965 15.952H22.029L22.797 13.088L23.773 9.648H25.629L26.637 13.088L27.421 15.952H27.485L28.141 13.088L28.973 9.648H30.877L28.589 18H26.381L25.309 14.32L24.685 12.048H24.637L24.029 14.32L22.973 18H20.797L18.493 9.648Z"
          fill="#FFA500"
        />
        <path
          d="M35.6538 18.192C35.0351 18.192 34.4804 18.0907 33.9898 17.888C33.5098 17.6747 33.0991 17.3813 32.7578 17.008C32.4271 16.624 32.1711 16.1653 31.9898 15.632C31.8084 15.088 31.7178 14.48 31.7178 13.808C31.7178 13.1467 31.8031 12.5493 31.9738 12.016C32.1551 11.4827 32.4111 11.0293 32.7418 10.656C33.0724 10.272 33.4778 9.97867 33.9578 9.776C34.4378 9.56267 34.9818 9.456 35.5898 9.456C36.2404 9.456 36.8058 9.568 37.2858 9.792C37.7658 10.016 38.1604 10.32 38.4698 10.704C38.7791 11.088 39.0084 11.536 39.1578 12.048C39.3178 12.5493 39.3978 13.088 39.3978 13.664V14.336H33.8458V14.544C33.8458 15.152 34.0164 15.6427 34.3578 16.016C34.6991 16.3787 35.2058 16.56 35.8778 16.56C36.3898 16.56 36.8058 16.4533 37.1258 16.24C37.4564 16.0267 37.7498 15.7547 38.0058 15.424L39.1098 16.656C38.7684 17.136 38.2991 17.5147 37.7018 17.792C37.1151 18.0587 36.4324 18.192 35.6538 18.192ZM35.6218 10.992C35.0778 10.992 34.6458 11.1733 34.3258 11.536C34.0058 11.8987 33.8458 12.368 33.8458 12.944V13.072H37.2698V12.928C37.2698 12.352 37.1258 11.888 36.8378 11.536C36.5604 11.1733 36.1551 10.992 35.6218 10.992Z"
          fill="#FFA500"
        />
        <path
          d="M41.1996 18V9.648H43.2476V11.376H43.3276C43.381 11.152 43.461 10.9387 43.5676 10.736C43.685 10.5227 43.8343 10.336 44.0156 10.176C44.197 10.016 44.4103 9.888 44.6556 9.792C44.9116 9.696 45.205 9.648 45.5356 9.648H45.9836V11.584H45.3436C44.6503 11.584 44.1276 11.6853 43.7756 11.888C43.4236 12.0907 43.2476 12.4213 43.2476 12.88V18H41.1996Z"
          fill="#FFA500"
        />
        <path
          d="M50.7788 18.192C50.1601 18.192 49.6054 18.0907 49.1148 17.888C48.6348 17.6747 48.2241 17.3813 47.8828 17.008C47.5521 16.624 47.2961 16.1653 47.1148 15.632C46.9334 15.088 46.8428 14.48 46.8428 13.808C46.8428 13.1467 46.9281 12.5493 47.0988 12.016C47.2801 11.4827 47.5361 11.0293 47.8668 10.656C48.1974 10.272 48.6028 9.97867 49.0828 9.776C49.5628 9.56267 50.1068 9.456 50.7148 9.456C51.3654 9.456 51.9308 9.568 52.4108 9.792C52.8908 10.016 53.2854 10.32 53.5948 10.704C53.9041 11.088 54.1334 11.536 54.2828 12.048C54.4428 12.5493 54.5228 13.088 54.5228 13.664V14.336H48.9708V14.544C48.9708 15.152 49.1414 15.6427 49.4828 16.016C49.8241 16.3787 50.3308 16.56 51.0028 16.56C51.5148 16.56 51.9308 16.4533 52.2508 16.24C52.5814 16.0267 52.8748 15.7547 53.1308 15.424L54.2348 16.656C53.8934 17.136 53.4241 17.5147 52.8268 17.792C52.2401 18.0587 51.5574 18.192 50.7788 18.192ZM50.7468 10.992C50.2028 10.992 49.7708 11.1733 49.4508 11.536C49.1308 11.8987 48.9708 12.368 48.9708 12.944V13.072H52.3948V12.928C52.3948 12.352 52.2508 11.888 51.9628 11.536C51.6854 11.1733 51.2801 10.992 50.7468 10.992Z"
          fill="#FFA500"
        />
        <path
          d="M61.5086 16.608H61.4286C61.2793 17.088 60.9966 17.472 60.5806 17.76C60.1646 18.048 59.6793 18.192 59.1246 18.192C58.0686 18.192 57.258 17.8133 56.6926 17.056C56.138 16.2987 55.8606 15.216 55.8606 13.808C55.8606 12.4107 56.138 11.3387 56.6926 10.592C57.258 9.83467 58.0686 9.456 59.1246 9.456C59.6793 9.456 60.1646 9.6 60.5806 9.888C60.9966 10.1653 61.2793 10.544 61.4286 11.024H61.5086V6.16H63.5566V18H61.5086V16.608ZM59.7806 16.496C60.2713 16.496 60.682 16.3787 61.0126 16.144C61.3433 15.9093 61.5086 15.5733 61.5086 15.136V12.48C61.5086 12.0747 61.3433 11.7493 61.0126 11.504C60.682 11.2587 60.2713 11.136 59.7806 11.136C59.2473 11.136 58.8153 11.3173 58.4846 11.68C58.1646 12.032 58.0046 12.5067 58.0046 13.104V14.544C58.0046 15.1413 58.1646 15.616 58.4846 15.968C58.8153 16.32 59.2473 16.496 59.7806 16.496Z"
          fill="#FFA500"
        />
        <path
          d="M69.6996 6.16H71.7476V11.024H71.8116C71.961 10.544 72.2436 10.1653 72.6596 9.888C73.0756 9.6 73.561 9.456 74.1156 9.456C75.1823 9.456 75.993 9.83467 76.5476 10.592C77.113 11.3387 77.3956 12.4107 77.3956 13.808C77.3956 15.216 77.113 16.2987 76.5476 17.056C75.993 17.8133 75.1823 18.192 74.1156 18.192C73.561 18.192 73.0756 18.048 72.6596 17.76C72.2543 17.472 71.9716 17.088 71.8116 16.608H71.7476V18H69.6996V6.16ZM73.4596 16.496C73.993 16.496 74.425 16.32 74.7556 15.968C75.0863 15.616 75.2516 15.1413 75.2516 14.544V13.104C75.2516 12.5067 75.0863 12.032 74.7556 11.68C74.425 11.3173 73.993 11.136 73.4596 11.136C72.969 11.136 72.5583 11.2587 72.2276 11.504C71.9076 11.7493 71.7476 12.0747 71.7476 12.48V15.136C71.7476 15.5733 71.9076 15.9093 72.2276 16.144C72.5583 16.3787 72.969 16.496 73.4596 16.496Z"
          fill="#FFA500"
        />
        <path
          d="M84.2728 9.648H86.1928L82.8328 19.488C82.7261 19.7867 82.6034 20.0427 82.4648 20.256C82.3368 20.48 82.1821 20.6613 82.0008 20.8C81.8194 20.9387 81.6008 21.04 81.3448 21.104C81.0888 21.168 80.7901 21.2 80.4488 21.2H79.2168V19.568H80.7208L81.1048 18.4L78.1128 9.648H80.1448L81.6008 14.064L82.1448 16.176H82.2408L82.8168 14.064L84.2728 9.648Z"
          fill="#FFA500"
        />
        <g clipPath="url(#clip1)">
          <path
            d="M131.711 4.05588H125.386C122.491 4.05588 121.036 5.49648 121.036 8.38507C121.036 11.1702 122.484 12.5665 125.386 12.5665H127.561C128.526 12.5665 129.009 13.0984 129.009 14.1549C129.009 15.1153 128.526 15.5955 127.561 15.5955H121.237C121.133 15.5955 121.044 15.6841 121.044 15.7949V18.4028C121.044 18.4471 121.081 18.4841 121.125 18.4841H127.569C130.464 18.4841 131.919 17.0435 131.919 14.1549C131.919 11.1776 130.471 9.68531 127.569 9.68531H125.394C124.429 9.68531 123.946 9.25682 123.946 8.38507C123.946 7.42467 124.429 6.94447 125.394 6.94447H131.718C131.822 6.94447 131.911 6.85582 131.911 6.745V4.25535C131.904 4.14453 131.815 4.05588 131.711 4.05588Z"
            fill="#FFA500"
          />
          <path
            d="M145.095 8.38507C145.095 5.49648 143.648 4.05588 140.745 4.05588H137.843C134.948 4.05588 133.493 5.49648 133.493 8.38507V14.1549C133.493 17.0435 134.94 18.4841 137.843 18.4841H140.745C143.64 18.4841 145.095 17.0435 145.095 14.1549V8.38507ZM142.193 14.1549C142.193 15.1153 141.71 15.5955 140.745 15.5955H137.843C136.878 15.5955 136.395 15.1153 136.395 14.1549V8.38507C136.395 7.52071 136.878 7.08484 137.843 7.08484H140.745C141.71 7.08484 142.193 7.52071 142.193 8.38507V14.1549Z"
            fill="#FFA500"
          />
          <path
            d="M146.973 3.04359H149.482C149.594 3.04359 149.683 2.95494 149.683 2.84412V0.354467C149.683 0.243651 149.594 0.154999 149.482 0.154999H146.981C146.869 0.154999 146.78 0.243651 146.78 0.354467V2.84412C146.78 2.95494 146.869 3.04359 146.973 3.04359Z"
            fill="#FFA500"
          />
          <path
            d="M149.675 18.2844V4.24775C149.675 4.13693 149.586 4.04828 149.475 4.04828H146.973C146.862 4.04828 146.773 4.13693 146.773 4.24775V18.2844C146.773 18.3952 146.862 18.4839 146.973 18.4839H149.482C149.586 18.4839 149.675 18.3952 149.675 18.2844Z"
            fill="#FFA500"
          />
          <path
            d="M157.93 4.05588C156.594 4.05588 155.458 4.4622 154.538 5.28224C154.486 5.32656 154.419 5.34134 154.352 5.32656C154.285 5.31179 154.233 5.26007 154.218 5.19358L153.914 4.19624C153.892 4.11498 153.81 4.05588 153.728 4.05588H151.613C151.501 4.05588 151.412 4.14453 151.412 4.25535V18.292C151.412 18.4028 151.501 18.4915 151.613 18.4915H154.114C154.226 18.4915 154.315 18.4028 154.315 18.292V7.78667C154.315 7.71279 154.359 7.63892 154.426 7.60936C155.376 7.1661 156.542 6.94447 157.937 6.94447C159.385 6.94447 160.112 7.66847 160.112 9.10907V18.292C160.112 18.4028 160.201 18.4915 160.313 18.4915H162.822C162.933 18.4915 163.022 18.4028 163.022 18.292V9.10907C163 5.74028 161.308 4.05588 157.93 4.05588Z"
            fill="#FFA500"
          />
          <path
            d="M118.713 6.1909L107.972 0.0221631C107.92 -0.00738771 107.86 -0.00738771 107.816 0.0221631L97.0817 6.1909C97.0297 6.22045 97 6.27216 97 6.33126V18.6761C97 18.7352 97.0297 18.7869 97.0817 18.8091L107.823 24.9778C107.875 25.0074 107.934 25.0074 107.979 24.9778L118.72 18.8091C118.772 18.7796 118.802 18.7278 118.802 18.6761V6.33126C118.795 6.27216 118.765 6.22045 118.713 6.1909ZM115.291 16.6519C115.291 16.711 115.261 16.7701 115.209 16.7996L107.979 20.9515C107.927 20.9811 107.86 20.9811 107.808 20.9515L100.578 16.7996C100.526 16.7701 100.489 16.711 100.489 16.6519V8.34811C100.489 8.28901 100.519 8.22991 100.578 8.20035L107.808 4.04846C107.86 4.01891 107.927 4.01891 107.979 4.04846L115.209 8.20035C115.261 8.22991 115.291 8.28901 115.291 8.34811V16.6519Z"
            fill="#FFA500"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="162.688"
            height="25"
            fill="white"
            transform="translate(0.312012)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect width="66" height="25" fill="white" transform="translate(97)" />
        </clipPath>
      </defs>
    </>
  ),
  name: 'Poweredby',
})
